import React, { useState, useEffect, useContext } from 'react'
import SurveyAnalyticsTabulator from './SurveyAnalyticsTabulator'
import {
  getSurveryResponseDataByDeploymentId,
  getFormByDeploymentId,
  deleteSurveyAnswerById,
} from '../../utilities/restService'
import { CustomQuestions } from '../surveyAnswer/CustomQuestions'
import { useEnv } from '@praxis/component-runtime-env'
import { formatAMAndPM } from '../../utilities/date'
import {
  TableExtensions,
  DocumentHelper,
} from 'survey-analytics/survey.analytics.tabulator.js'
import { confirmAlert } from 'react-confirm-alert' //Import
import 'react-confirm-alert/src/react-confirm-alert.css' //Importcss
import { ApplicationContext } from '../contexts/ApplicationContext'
import { useParams } from 'react-router-dom'
import { ROLE } from '../../utilities/constants'

export function AnalyticsTabulatorPage(props) {
  const env = useEnv()
  const [isFormLoading, setIsFormLoading] = useState(false)
  const [formData, setFormData] = useState({})
  const [isSurveyLoading, setIsSurveyLoading] = useState(false)
  const [surveyData, setSurveyData] = useState([])
  const { setAppId, roleIdentities } = useContext(ApplicationContext)

  let { appId, id } = useParams()

  var filterRoles = roleIdentities
    ?.filter((r) => r.application_id.toString() === appId.toString())
    .map((r) => r.role)

  useEffect(() => {
    if (appId !== undefined) {
      setAppId(appId)
    }
  }, [appId, setAppId])

  useEffect(() => {
    function getFormDataByDeploymentId() {
      if (id !== undefined) {
        setIsFormLoading(true)
        getFormByDeploymentId(id)
          .then((res) => {
            if (res.data?.survey_form_json?.pages.length > 0) {
              var obj1 = {
                type: 'text',
                name: 'response_id',
                title: 'Response Id',
              }
              var obj2 = {
                type: 'text',
                name: 'response_status',
                title: 'Response Status',
              }

              var obj3 = {
                type: 'text',
                name: 'submitted_by',
                title: 'Submitted By',
              }
              var obj4 = {
                type: 'text',
                name: 'submitted_date',
                title: 'Submitted Date (UTC Format)',
              }

              res.data?.survey_form_json?.pages[0].elements.unshift(obj4)
              res.data?.survey_form_json?.pages[0].elements.unshift(obj3)
              res.data?.survey_form_json?.pages[0].elements.unshift(obj2)
              res.data?.survey_form_json?.pages[0].elements.unshift(obj1)

              setFormData(res.data?.survey_form_json)
            }

            setIsFormLoading(false)
          })
          .catch((error) => {
            setIsFormLoading(false)
          })
      }
    }

    function getSurveyResponseByDeploymentId() {
      if (id !== undefined) {
        setIsSurveyLoading(true)
        getSurveryResponseDataByDeploymentId(id)
          .then((res) => {
            setSurveyData(
              res.data.map((s) => {
                s.survey_answer_json.response_id = s.id
                s.survey_answer_json.response_status = s.status
                s.survey_answer_json.submitted_by = s.submitted_by
                s.survey_answer_json.deployment_mode = s.deployment_mode
                s.survey_answer_json.submitted_date =
                  s.submitted_date === null
                    ? ''
                    : formatAMAndPM(new Date(s.submitted_date))
                return s.survey_answer_json
              }),
            )
            setIsSurveyLoading(false)
          })
          .catch((error) => {
            setIsSurveyLoading(false)
          })
      }
    }
    if (Object.keys(formData).length === 0) {
      getFormDataByDeploymentId()
      getSurveyResponseByDeploymentId()
    }
  }, [id, formData])

  function refreshPage() {
    window.location.reload(false)
  }

  function updateResponseId(responseId) {
    if (id !== undefined) {
      var url = '/surveyAnswerUpdateUrl/' + id + '/answers/' + responseId
      window.open(url, '_blank')
    }
  }

  function viewResponseId(responseId) {
    if (id !== undefined) {
      var url = '/surveyAnswerUpdateUrl/' + id + '/viewAnswers/' + responseId
      window.open(url, '_blank')
    }
  }

  function deleteResponseId(responseId) {
    confirmAlert({
      title: 'Assessment Responses',
      message:
        'Are you sure you want to delete this response id:' + responseId + '?',
      closeOnEscape: false,
      closeOnClickOutside: false,
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            deleteSurveyAnswerById(responseId)
              .then((res) => {
                refreshPage()
              })
              .catch((error) => {})
          },
        },
        {
          label: 'No',
          onClick: () => {
            return false
          },
        },
      ],
    })
  }

  if (
    filterRoles?.includes(ROLE.ADMIN) &&
    TableExtensions.findExtension('details', 'delete') === null &&
    surveyData.length > 0
  ) {
    TableExtensions.registerExtension({
      location: 'details',
      name: 'delete',
      visibleIndex: 1,
      render: (table, opt) => {
        const btn = DocumentHelper.createElement('button', 'sa-table__btn', {
          innerHTML: 'DeleteResult',
          onclick: (e) => {
            e.stopPropagation()
            deleteResponseId(opt.row.innerRow._row.data.response_id)
          },
        })
        return btn
      },
    })
  }

  if (TableExtensions.findExtension('details', 'update') !== null) {
    TableExtensions.unregisterExtension('details', 'update')
  }

  if (
    filterRoles?.includes(ROLE.ADMIN) &&
    TableExtensions.findExtension('details', 'update') === null &&
    surveyData.length > 0
  ) {
    TableExtensions.registerExtension({
      location: 'details',
      name: 'update',
      visibleIndex: 1,
      render: (table, opt) => {
        const btn = DocumentHelper.createElement('button', 'sa-table__btn', {
          innerHTML: 'UpdateResult',
          onclick: (e) => {
            e.stopPropagation()
            updateResponseId(opt.row.innerRow._row.data.response_id)
          },
        })
        return btn
      },
    })
  }

  if (
    TableExtensions.findExtension('details', 'view') === null &&
    surveyData.length > 0
  ) {
    TableExtensions.registerExtension({
      location: 'details',
      name: 'view',
      visibleIndex: 1,
      render: (table, opt) => {
        const btn = DocumentHelper.createElement('button', 'sa-table__btn', {
          innerHTML: 'ViiewResult',
          onclick: (e) => {
            e.stopPropagation()
            viewResponseId(opt.row.innerRow._row.data.response_id)
          },
        })
        return btn
      },
    })
  }

  return (
    <React.Fragment>
      <div style={{ margin: '20px' }}>
        <h3>Field assessment results in the table form</h3>
        {!isFormLoading && !isSurveyLoading && appId !== 0 ? (
          <div>
            <CustomQuestions />
            <SurveyAnalyticsTabulator
              formData={formData}
              surveyData={surveyData}
              env={env}
            />
          </div>
        ) : (
          'loading ....'
        )}
      </div>
    </React.Fragment>
  )
}
